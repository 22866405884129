import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import Section from "../../Elements/Section"
import ActionButtons from "../../Elements/ActionButtons"
import InvalidFormMessage from "../../Elements/InvalidFormMessage"
import DeliveryAddressSection from "./DeliveryDetails/DeliveryAddressSection"
import PersonalDetailsSection from "./DeliveryDetails/PersonalDetailsSection"

import { AppContext } from "../../../context/AppContext"
import { isObjectEmpty } from "../../../services/general"
import { getContextFromSession } from "../../../context/services/context"
import { specialOrderDeliveryDetailsValidationSchema } from "../utils/formData"

const DeliveryDetails = ({ nextRoute, backRoute }) => {
  const { dispatch, state } = useContext(AppContext)
  let formValues = getContextFromSession()
  if (isObjectEmpty(formValues)) formValues = state

  const handleSubmit = async (values) => {
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, ...values },
      },
    })
    await dispatch({ type: "SAVE_DELIVERY_DETAILS", payload: values })
    navigate(nextRoute)
  }

  return (
    <Formik
      initialValues={{
        ...formValues.epharmacy,
        ...formValues.scpwdId,
        contactPerson: formValues?.epharmacy?.doctorAccountName
          ? formValues?.epharmacy?.contactPerson
          : [],
      }}
      onSubmit={handleSubmit}
      validationSchema={specialOrderDeliveryDetailsValidationSchema}
    >
      {({ values, setFieldValue, handleChange, submitCount, isValid }) => (
        <Form>
          <Section title="Doctor/Key Account Details">
            <PersonalDetailsSection
              handleChange={handleChange}
              values={values}
            />
          </Section>
          <Section title="Delivery Address">
            <DeliveryAddressSection
              values={values}
              setFieldValue={setFieldValue}
            />
          </Section>
          {!isValid && submitCount > 0 && (
            <InvalidFormMessage color="danger" name="missingRequiredFields" />
          )}

          <ActionButtons
            submit={{ label: "Next: Select Channel" }}
            back={{ label: "Back", link: backRoute }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default DeliveryDetails
