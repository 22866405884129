import React, { Fragment } from "react"
import FormInput from "../../../Elements/Form/FormInput"
import FormCheckbox from "elements/Form/FormCheckbox"

const contactPersonBlurb = "Contact this number for order and delivery updates"

const PersonalDetailsSection = ({ values }) => {
  return (
    <Fragment>
      <FormInput
        name="doctorAccountName"
        label="Doctor/Account Name"
        placeholder="Juan Dela Cruz"
        isRequired
      />
      <FormInput
        name="emailAddress"
        label="Email Address"
        placeholder="jdelacruz@mail.com"
        isRequired
      />
      <FormInput
        name="mobileNumber"
        label="Mobile Number"
        placeholder="09123456789"
        isRequired
        maxLength={11}
        isNumeric
      />
      <FormCheckbox
        name="contactPerson"
        values={values?.contactPerson}
        options={[contactPersonBlurb]}
        isRequired
      />
      {values?.contactPerson.length === 0 && (
        <section>
          <FormInput
            name="authorizedPerson.fullName"
            label="Contact Person"
            isRequired
            placeholder="Juan Dela Cruz"
          />
          <FormInput
            name="authorizedPerson.contactNumber"
            label="Mobile Number of Contact Person"
            placeholder="09123456789"
            isRequired
            maxLength={15}
            isNumeric
          />
        </section>
      )}
      <FormInput
        name="landlineNumber"
        label="Landline"
        placeholder="88881234"
        maxLength={11}
        isNumeric
        autocomplete="off"
      />
    </Fragment>
  )
}

export default PersonalDetailsSection
